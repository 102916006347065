import React, { Component } from "react"
import MblNav from "../images/mbl-nav.svg"

import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Logo from "./Logo"

export default class Navbar extends Component {
  state = {
    isOpen: false,
    modalShow: false,
    dropdownShow: false,
  }
  toggleNav = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  showDropDown = () => {
    this.setState({
      dropdownShow: !this.state.dropdownShow,
    })
  }
  showModal = e => {
    e.preventDefault()
    this.setState({
      modalShow: true,
    })
  }

  closeModal = () => {
    this.setState({
      modalShow: false,
    })
  }
  render() {
    let { hideItem, bgColor } = this.props
    return (
      <nav className="navbar navbar-expand-lg" id="navbar" style={{backgroundColor: `${bgColor}`}}>
        <div className="container">
          <div className="navbar-header">
            <Logo />
            <ul className="mbl-menu ml-auto">
              <li>
                <a 
                  className="primary-bg" 
                  href="/book" 
                  // onClick={this.showModal}
                >
                  <span>Free</span> Consulting
                </a>
              </li>
              {/* <li>
                <AnchorLink
                  to="/#contact-form-section"
                  title="Get Quote"
                  className="white-bg xs-hidden"
                  stripHash
                />
                
              </li> */}
            </ul>
            <button
              className={`${
                this.state.isOpen
                  ? "navbar-toggler"
                  : "navbar-toggler collapsed"
              }`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleNav}
            >
              <img
                src={MblNav}
                className="navbar-toggler-icon"
                alt="Pandigital"
              />
            </button>
          </div>
          <div
            className={`${
              this.state.isOpen
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto">
              <li className={`nav-item ${hideItem ? hideItem : ""}`}>
                {/* <Link
                  className="nav-link"
                  href="#work-section"
                >
                  Our Work
                </Link> */}
                <AnchorLink
                  to="/#work-section"
                  title="Our Work"
                  className="nav-link"
                  stripHash
                />
              </li>
              {/* <li className={`nav-item ${hideItem ? hideItem : ""}`}>
                <AnchorLink
                  to="/#about-section-3"
                  title="About Us"
                  className="nav-link"
                  stripHash
                />
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/careers"
                >
                  Careers
                </Link>
              </li>
              <li className="nav-item">
                <AnchorLink
                  to="/#contact-form-section"
                  title="Contact"
                  className="nav-link"
                  stripHash
                />
              </li>
              <li className="nav-item consulting">
                <a
                  className="nav-link primary-bg"
                  href="/book"
                  // onClick={this.showModal}
                >
                  Free Consulting
                </a>
              </li>
              {/* <li className="nav-item quotes">
                <AnchorLink
                  to="/#contact-form-section"
                  title="Get Quote"
                  className="white-bg xs-hidden"
                  stripHash
                />
              </li> */}
              {/* <li
                className={`nav-item dropdown language ${
                  this.state.dropdownShow ? "show" : ""
                }`}
                onClick={this.showDropDown}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={this.state.dropdownShow ? "true" : "false"}
                >
                  En
                </a>
                <div
                  className={`dropdown-menu ${
                    this.state.dropdownShow ? "show" : ""
                  }`}
                  aria-labelledby="navbarDropdown"
                >
                  <a className="dropdown-item" href="#">
                    De
                  </a>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
        <div
          className={`modal-container box-shadow ${
            this.state.modalShow ? "show" : "hidden"
          }`}
        >
          <span className="close" onClick={this.closeModal}>
            X
          </span>
          <h3 className="title">Select Schedule</h3>
          <div className="modal-content">
            <iframe
              src="https://calendly.com/pandigita/consulting"
              height="100%"
            ></iframe>
          </div>
        </div>
      </nav>
    )
  }
}
