import React from "react"
import LogoImg from "../images/logo.svg"
import { Link } from "gatsby"

const Logo = () => {
  return (
    <>
      <Link className="navbar-brand" to="/">
        <img src={LogoImg} alt="Pandigita" />
      </Link>
    </>
  )
}

export default Logo
