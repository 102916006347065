import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/style.scss"
import "../scss/responsive.scss"

const Layout = ({children}) => {
  return (
    <>
      {children}
    </>
  )
}

export default Layout
